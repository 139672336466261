import React from 'react';

import Tooltip from './Tooltip';

import Settings from '../config.js';

import FormPanel from './FormPanel';
import SchoolPicker from './SchoolPicker';
import SchoolPickerLegacyForm from './SchoolPickerLegacyForm';
import StudentPicker from './StudentPicker';
import TeacherInput from './TeacherInput';

class RequestForm extends React.Component {
  constructor(props) {
    super(props);

    this.MAX_NUM_STUDENTS = 50;

    this.state = {
      status: 'default',
      error: '',

      steps: {
        schoolclasses: {
          status: 'default'
        },
      },

      school: null,
      legacySchoolForm: false,

      schoolclasses: [],
      studentsConfirmed: false,

      teacher: null,
      teacherConfirmed: false,

      requiredPrivacy: [
        'privacy-1'
      ],
      acceptedPrivacy: []
    }

    this.getCurrStep = this.getCurrStep.bind(this);

    this.handleSelectSchool = this.handleSelectSchool.bind(this);
    this.handleRemoveSchool = this.handleRemoveSchool.bind(this);
    this.handleEnterManually = this.handleEnterManually.bind(this);

    this.handleAddSchoolclass = this.handleAddSchoolclass.bind(this);
    this.handleRemoveSchoolclass = this.handleRemoveSchoolclass.bind(this);
    this.handleConfirmSchoolclasses = this.handleConfirmSchoolclasses.bind(this);
    this.handleUnconfirmSchoolclasses = this.handleUnconfirmSchoolclasses.bind(this);

    this.handleSaveTeacher = this.handleSaveTeacher.bind(this);
    this.handleUnconfirmTeacher = this.handleUnconfirmTeacher.bind(this);

    this.privacyOK = this.privacyOK.bind(this);
    this.handlePrivacyClick = this.handlePrivacyClick.bind(this);

    this.sendHttpRequest = this.sendHttpRequest.bind(this);
    this.sendOrders = this.sendOrders.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.startOver = this.startOver.bind(this);
  }

  componentDidMount() {
    // the following just for testing
    var skipTo = window.location.hostname == 'localhost' ? 0 : 0;
    if (skipTo) {
      let school;

      if (0) {
        // from search
        school = {
          // name/ID
          "id": "BAMM003008",
          "name": "CARDUCCI",

          // location info
          "address": "VIA S.FRANCESCO D'ASSISI 63",
          "postcode": "70122",
          "location_id": "ITF47A662",

          // extra location info (for displaying)
          "city_name": "Bari",
          "province_iso_code": "BA",
          "region_name": "Puglia",

          // contact info
          "email": "bamm003008@istruzione.it",
          "certified_email": "",
          "website": "www.carduccibari.it",

          // useless
          "ref_id": "_:b7549",
          "type": "SCUOLA PRIMO GRADO",
          "schoolyear": "2018",
          "debugging info (localhost only)":{},
          "_score":726
        };
      } else {
        // manually-entered
        school ={
          // name/ID
          "name": "Scuola XXX",

          // location info
          "address": "Ul. Grabiszynska 18/9",
          "postcode": "53-502",
          "location_id": "ITG14A089",

          // extra location info (for displaying)
          "city_name": "Agrigento",
          "province_iso_code": "AG",
          "region_name": "Sicilia",

          // contact info (completely missing)
        };
      }
      this.handleSelectSchool(school);
    }

    if (skipTo >= 2) {
      this.handleAddSchoolclass({
        schoolyear: "2018",
        sec : "A",
        students : "20",
        type : "KIND-3"
      });
      this.handleAddSchoolclass({
        schoolyear: "2018",
        sec : "A",
        students : "25",
        type : "KIND-ETC"
      });
      this.handleConfirmSchoolclasses();
    }

    if (skipTo >= 3) {
      this.handleSaveTeacher({
        first_name: 'Niccolo',
        last_name: 'Brogi',
        email: 'me@nbrogi.com',
        phone: '+48 555 555 5555',
        subject: 'Tecnologia ed informatica'
      });
    }

    if (skipTo >= 4) {
      let e = new Event('fake-event-for-submit');
      this.handleSubmit(e);
    }
  }

  getCurrStep() {
    const schoolOK = Boolean(this.state.school);
    const schoolclassesOK = this.props.noStudents || (this.state.schoolclasses.length && this.state.studentsConfirmed);
    const teacher = Boolean(this.state.teacher);

    if (schoolOK && schoolclassesOK && teacher) {
      return 4;
    }
    if (schoolOK && schoolclassesOK) {
      return 3;
    }
    if (this.state.school) {
      return 2;
    }

    return 1;
  }

  handleSelectSchool(school) {
    this.setState(function(prevState) {
      prevState.school = school;

      return prevState;
    });
  }
  handleRemoveSchool() {
    this.setState(function(prevState) {
      prevState.school = null;

      return prevState;
    });
  }
  handleEnterManually() {
    // first, cancel whatever was entered
    this.handleRemoveSchool();

    // switch to regular form
    return this.setState({
      legacySchoolForm: true
    });
  }

  handleAddSchoolclass(schoolclass) {
    this.setState(function(prevState) {
      prevState.schoolclasses.push(schoolclass);

      return prevState;
    });
  }
  handleRemoveSchoolclass(remove) {
    this.setState(function(prevState) {
      let index;
      for (let i=0; i<prevState.schoolclasses.length; i++) {
        if (prevState.schoolclasses[i].schoolyear == remove.schoolyear && prevState.schoolclasses[i].type == remove.type) {
          index = i;
        }
      }

      prevState.schoolclasses.splice(index, 1);

      return prevState;
    });
  }
  handleConfirmSchoolclasses() {
    this.setState(function(prevState) {
      prevState.studentsConfirmed = true;

      return prevState;
    });
  }
  handleUnconfirmSchoolclasses() {
    this.setState(function(prevState) {
      prevState.studentsConfirmed = false;

      return prevState;
    });
  }

  handleSaveTeacher(teacher) {
    this.setState(function(prevState) {
      prevState.teacher = teacher;
      prevState.teacherConfirmed = true;

      return prevState;
    });
  }
  handleUnconfirmTeacher() {
    this.setState(function(prevState) {
      prevState.teacherConfirmed = false;

      return prevState;
    });
  }

  handlePrivacyClick(e) {
    var checkbox = e.target;

    this.setState(function(prevState) {
      prevState.acceptedPrivacy[checkbox.name] = checkbox.checked;

      return prevState;
    });
  }
  /** TIWI **/
  privacyOK_Colibri() {
    for (var i in this.state.requiredPrivacy) {
      var privacyName = this.state.requiredPrivacy[i];
      if (!this.state.acceptedPrivacy[privacyName]) {
        return false;
      }
    }

    return true;
  }
  privacyOK() {
    /** Per incompatibilità da verificare con le ns librerie ridefiniamo il check sulla privacy **/
    var result = true;

    for(var index=0;index<this.state.requiredPrivacy.length;index++) {
      if(typeof this.state.acceptedPrivacy[this.state.requiredPrivacy[index]] == "undefined") result = false;
      if(this.state.acceptedPrivacy[this.state.requiredPrivacy[index]]!==true) result = false;
      result = result && true;
    }

    return result;

  }
  /** TIWI **/


  // make queued requests
  sendHttpRequest(method, url, data) {
    return new Promise(function (resolve, reject) {
      var xhr = new XMLHttpRequest();

      xhr.open(method, url);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.onload = function () {
        if (this.status >= 200 && this.status < 300) {
          resolve(JSON.parse(xhr.response));
        } else {
          reject({
            status: this.status,
            statusText: xhr.statusText
          });
        }
      };

      xhr.onerror = function () {
        reject({
          status: this.status,
          statusText: xhr.statusText
        });
      };

      xhr.send(JSON.stringify(data));
    });
  }

  // create request for submit function
  sendOrders(kits, orderPayload) {
    // create requests
    let numLeft = (this.state.schoolclasses ? this.state.schoolclasses.length : 0) * kits.length;

    kits.forEach((kitId, i) => {
      if (this.state.error) {
        console.error('WARNING: we have an error, will not send more requests...');

        return;
      }

      // first, create order (will send confirmation email only for first one)
      const orderEndpoint = Settings.API_V3_URL.replace(/\/+$/, '') + '/kit-orders?ref_kits=' + kits.join(',');
      let orderId = '';
      this.sendHttpRequest('POST', orderEndpoint, orderPayload)
        .then((response) => {
          const done = () => {
            const endpoint = Settings.API_V3_URL.replace(/\/+$/, '') + '/kit-orders/' + orderId + '/confirmations?teacher_email=' + this.state.teacher.email;

            this.sendHttpRequest('POST', endpoint, null);

            this.setState({
              status: 'done',
              error: false
            });

            // exec callback
            if (this.props.onSuccess) {
              this.props.onSuccess(this.state.teacher, this.state.schoolclasses);
            }
          };

          orderId = response.data.order_id;
          if (!orderId) {
            alert('No order ID, cannot continue');
          } else {
            if (this.props.noStudents) {
              return done();
            }

            let itemsEndpoint = Settings.API_V3_URL.replace(/\/+$/, '') + '/kit-orders/' + orderId + '/items';
            this.state.schoolclasses.forEach((schoolclass) => {
              let itemsPayload = {};
              itemsPayload.product_id = kitId;
              itemsPayload.schoolclass_type = schoolclass.type;
              itemsPayload.schoolclass_schoolyear = schoolclass.schoolyear;
              itemsPayload.schoolclass_sec = schoolclass.sec;
              itemsPayload.schoolclass_num_students = schoolclass.students;

              this.sendHttpRequest('POST', itemsEndpoint, itemsPayload)
                .then((data) => {
                  numLeft--;

                  if (numLeft == 0) {
                    return done();
                  }
                })
                .catch((err) => {
                  this.setState({
                    status: 'done',
                    error: 'Impossibile salvare richiesta: ' + err.statusText
                  });

                  alert('Spiacenti, impossibile salvare richiesta');

                  console.error('ERROR!');
                  console.dir(err);
                });
            });
          }
        })
        .catch((err) => {
          this.setState({
            status: 'done',
            error: 'Impossibile salvare ordine: ' + err.statusText
          });

          alert('Spiacenti, impossibile salvare ordine');

          console.error('ERROR!');
          console.dir(err);
        });
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    // check privacy
    if (!this.privacyOK()) {
      this.setState({
        status: 'done',
        error: 'Controllare accettazione privacy'
      });

      return false;
    }

    // const kits = this.props.kits;
    const kitIds = document.getElementById('progettiedu_kitrequest_form').getAttribute('data-kits').split(',').map((kitId) => {
      return String(kitId).replace(' ', '');
    });
    if (!kitIds.length) {
      this.setState({
        status: 'done',
        error: 'NO kit ID'
      });

      return false
    }

    const { refCode } = this.props;

    this.setState({
      status: 'submitting',
      'error': ''
    }, () => {
      console.dir(this.state);
      let payload = {
        teacher_id: 1, // for compatibility
        teacher_subject: this.state.teacher.subject,
        teacher_first_name: this.state.teacher.first_name,
        teacher_last_name: this.state.teacher.last_name,
        teacher_email: this.state.teacher.email,
        teacher_phone: this.state.teacher.phone,
        teacher_city: 'N/D', // for compatibility
        teacher_province: 'N/D', // for compatibility
        teacher_ref_code: refCode || '',

        extra_value_str: window.location.pathname + '|form/latest|kit(s):' + kitIds.join(','),
        extra_value_int: ''
      };
      if (this.state.school && this.state.school.id) {
        payload = Object.assign(payload, {school_id: this.state.school.id});
      } else {
        payload = Object.assign(payload, {
          user_provided_school_school_type: this.state.school.type ? this.state.school.type : 'N/D',
          user_provided_school_name: this.state.school.name,
          user_provided_school_address: this.state.school.address,
          user_provided_school_city: this.state.school.city_name,
          user_provided_school_postcode: this.state.school.postcode,
          user_provided_school_zip_code: this.state.school.postcode, // for compatibility, send "school_zip_code" as well)
          user_provided_school_province: this.state.school.province_iso_code,
          user_provided_school_region: this.state.school.region_name,
          user_provided_school_phone: this.state.school.phone ? this.state.school.phone : 'N/D',
          user_provided_school_fax: '',
          user_provided_school_email: this.state.school.email,
          user_provided_school_certified_email: this.state.school.certified_email,
          user_provided_school_manager: this.state.school.manager_name ? this.state.school.manager_name : 'N/D',
        });
      }

      // send order
      this.sendOrders(kitIds, payload);
    });
  }

  startOver() {
    this.setState({
      school: null,
      legacySchoolForm: false,

      schoolclasses: [],
      studentsConfirmed: false,

      teacher: null,
      teacherConfirmed: false,

      status: 'default',
      error: null,

      acceptedPrivacy: []
    });
  }

  render() {
    const colors = this.props.colors ? this.props.colors : {
      text: '#FFFFFF',
      primary: '#999999',
      darker: '#444444',
      lighter: '#CCCCCC'
    };

    var primaryButtonStyle = {color: colors.text, backgroundColor: colors.primary, borderColor: colors.darker};
    var editButtonStyle = {
      backgroundColor: 'transparent',
      border: 0,
      padding: 0
    };

    // if form OK, just print confirmation message
    if (this.state.status == 'done' && !this.state.error) {
      return (
        <div>
          <h1 className="colibri-progettiedu-kitrequestform__title--success"><i className="fa fa-check text-success" /> Grazie!</h1>

          {
            this.props.downloadUrl ? (
              <div>
                <h2><i className="fa fa-download" /> Scarica file</h2>
                <p><a href={this.props.downloadUrl} download target="_BLANK">Clicca qui</a> per effettuare il download</p>
              </div>
            ) : (
              <div>
                <p>La Sua richiesta è andata a buon fine! A breve riceverà un’email di conferma. I kit saranno inviati non prima del 15 ottobre 2023. Per qualsiasi domanda, non esitare a <a href="/contatti/">contattarci</a>.</p>
                <p><a href="javascript:void(0)" onClick={this.startOver}>Cliccate qui</a> per effettuare un'altra richiesta.</p>
              </div>
            )
          }
        </div>
      );
    }

    // if not success, let's print the form
    var step1, step2, step3, step4;

    if (this.getCurrStep() == 1) {
      if (this.state.legacySchoolForm) {
        step1 = (
          <SchoolPickerLegacyForm
            editable={this.state.status != 'done'}
            onSubmit={this.handleSelectSchool}
            colors={colors}
          />
        );
      } else {
        step1 = (
          <SchoolPicker
            editable={this.state.status != 'done'}
            region="it"
            language="it"
            types={['school']}
            placeholder=""
            button="Cerca"
            autofetch
            onSelect={this.handleSelectSchool}
            onExit={this.handleEnterManually}
            colors={colors}
          />
        );
      }
    } else {
      step1 = (
        <div>
          <div className="alert alert-light">
            <table border="0" width="100%">
              <tbody>
                <tr>
                  <td width="70%" align="left" valign="middle">
                    <h3>{this.state.school.name}</h3>
                    <p>
                      {this.state.school.address + ' ' + this.state.school.postcode + ' ' + this.state.school.city_name + ' (' + this.state.school.province_iso_code + ')'}
                      {/* {
                        this.state.school.parent_school ? (
                          <div>C/O {this.state.school.parent_school.name}, {this.state.school.parent_school.address + ' ' + this.state.school.parent_school.postcode + ' ' + this.state.school.parent_school.city_name + ' (' + this.state.school.parent_school.province_iso_code + ')'}</div>
                        ) : ''
                      } */}
                    </p>
                  </td>
                  <td width="30%" align="right" valign="middle">
                    {/* {this.state.status != 'done' ? <a className="align-middle" href="javascript:void(0);" onClick={this.handleRemoveSchool}><i className="fa fa-times" /> rimuovi</a> : ''} */}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      );
    }

    if (this.getCurrStep() >= 2) {
      step2 = (
        <StudentPicker
          editable={!this.state.studentsConfirmed}

          schoolclasses={this.state.schoolclasses}
          onSchoolclassAdd={this.handleAddSchoolclass}
          onSchoolclassRemove={this.handleRemoveSchoolclass}
          maxStudents={this.MAX_NUM_STUDENTS}

          colors={colors}

          onSave={this.handleConfirmSchoolclasses}
        />
      );
    }

    if (this.getCurrStep() >= 3) {
      step3 = (
        <TeacherInput
          editable={!this.state.teacherConfirmed}

          teacher={this.state.teacher}

          colors={colors}

          onSave={this.handleSaveTeacher}
        />
      );
    }

    if (this.getCurrStep() >= 4) {
      var step4Intro = '';

      var step4Privacy = (
        <div>
          <h4>Informazione privacy e consenso dati</h4>
          <p style={{height: '8vh', overflow: 'auto', border: '1px solid #999', padding: '7px'}}>
            Informativa d.lgs 196/2003 - Librì Progetti Educativi S.r.l., titolare del trattamento, si impegna a trattare con riservatezza i dati che Lei inserirà nel presente coupon al fine di inviare il materiale didattico da lei richiesto e relative informative destinate alla corretta e completa fruizione dei materiali e dei servizi ad esso collegati, quali, a titolo esemplificativo: Invio materiali didattici, Concorsi, Webinar, Crediti formativi, Riconferme annuali, Invio elaborati delle classi, Newsletter per la prenotazione di materiali didattici.
            <br /><br />
            I dati personali da Lei forniti saranno trattati anche con l’ausilio di mezzi elettronici, direttamente da Librì Progetti Educativi srl. Il conferimento dei dati personali è obbligatorio per le finalità di cui sopra, per il corretto adempimento degli obblighi di legge; pertanto, un eventuale rifiuto in merito rende impossibile lo svolgimento del servizio richiesto.
            Ai sensi dell’articolo 7 del D. Lgs. 196/2003, potrà sempre verificare i Suoi dati, aggiornarli, modificarli, integrarli, cancellarli e opporsi all’invio di messaggi rivolgendosi a: Librì Progetti Educativi S.r.l., Piazza degli Scarlatti, 2r 50125 Firenze – nella persona dell’Amministratore Delegato.
          </p>

          <p>Informativa Privacy completa: <a href="https://www.iubenda.com/privacy-policy/68797005" target="_BLANK">https://www.iubenda.com/privacy-policy/68797005</a></p>

          <p className="mt-2"><input type="checkbox" name="privacy-1" onClick={this.handlePrivacyClick} /> accetto</p>
        </div>
      );

      var step4SubmitButton = (
        <form onSubmit={this.handleSubmit}>
          <button type="submit" className={'btn btn-lg' + (this.privacyOK() == false ? ' btn-light disabled' : '')} style={this.privacyOK() ? primaryButtonStyle : {}}><i className="fa fa-save" />  Conferma {this.props.noShipping ? '' : 'e richiedi kit'}</button>
        </form>
      );

      var step4Tooltip = (
        <Tooltip colors={this.props.colors}>
          <h3>Controlla i dati che hai inserito e accetta le condizioni d'uso.</h3>
          <p>Clicca su "conferma" per {this.props.noShipping ? 'proseguire' : 'richiedere il kit'}.</p>
        </Tooltip>
      );

      if (this.state.status == 'default') {
        step4 = (
          <div>
            {step4Intro}

            {step4Privacy}

            {step4SubmitButton}

            {step4Tooltip}
          </div>
        );
      }

      if (this.state.status == 'submitting') {
        step4 = (
          <h2><img src="/images/spinner.svg" style={{height:'1.5em', verticalAlign: 'middle', marginLeft: '-5px'}} /> Spedizione in corso, attendere...</h2>
        )
      }

      if (this.state.status == 'done' && this.state.error) {
        step4 = (
          <div>
            {step4Intro}

            {step4Privacy}

            <table width="100%">
              <tr>
                <td width="1%">
                  {step4SubmitButton}
                </td>
                <td>
                  <p className="ml-2 text-danger"><i className="fa fa-exclamation-triangle text-danger" /> {this.state.error}</p>
                </td>
              </tr>
            </table>

            {step4Tooltip}

            {/* <p className="mt-2">Per assistenza, non esitare a <a href="/contatti/">contattarci</a>.</p> */}
          </div>
        )
      }
    }

    // calculate some info to print
    var numStudents = 0;
    this.state.schoolclasses.forEach(function(schoolclass) {
      if (schoolclass.students) numStudents += parseInt(schoolclass.students);
    });

    var numSteps = this.props.noStudents ? 3 : 4;
    var currStep = this.getCurrStep();
    var progressWidth = this.state.status == 'done' && !this.state.error ? '100%' : (100 / (numSteps + 1) * currStep) + '%'

    console.log('PROPS', this.props);

    return (
      <div>
        <div className="progress" style={{position: 'relative'}}>
          <div className="progress-bar" role="progressbar" style={{width: progressWidth, backgroundColor: colors.primary}}></div>
          <span style={{position: 'absolute', right: '7px'}}>
            {this.state.status == 'done' && !this.state.error ? <span style={{color: '#fff'}}>Grazie!</span> : <span>Sei al passaggio {currStep} di {numSteps}</span>}
          </span>
        </div>

        <FormPanel enabled={true} active={this.getCurrStep() == 1} colors={colors}>
          <table border="0" width="100%">
            <tr>
              <td width="50%" align="left" valign="middle">
              <h2>
                <big style={{fontWeight: 'bold'}}>1.</big> La tua scuola
                {this.state.school ? <small className="ml-2"><i className="fa fa-check text-done" /> {!this.props.isDigital && 'qui spediremo il kit educativo'}</small> : ''}
              </h2>
              </td>
              <td width="50%" align="right" valign="middle">
                {this.state.school ? <button className="btn" style={editButtonStyle} onClick={this.handleRemoveSchool}><i className="fa fa-pencil" /> Modifica</button> : ''}
              </td>
            </tr>
          </table>

          {step1}
        </FormPanel>

        {
          !this.props.noStudents && (
            <FormPanel enabled={this.getCurrStep() >= 2} active={this.getCurrStep() == 2} colors={colors}>
              <table border="0" width="100%">
                <tr>
                  <td width="50%" align="left" valign="middle">
                    <h2>
                      <big style={{fontWeight: 'bold'}}>2.</big> I tuoi alunni
                      {this.state.studentsConfirmed ? <small className="ml-2"><i className="fa fa-check text-done" /> riceverai kit per {numStudents} alunni</small> : numStudents ? '(' + numStudents + ')' : ''}
                    </h2>
                  </td>
                  <td width="50%" align="right" valign="middle">
                    {this.state.studentsConfirmed ? <button className="btn" style={editButtonStyle} onClick={this.handleUnconfirmSchoolclasses}><i className="fa fa-pencil" /> Modifica</button> : ''}
                  </td>
                </tr>
              </table>

              {step2}
            </FormPanel>
          )
        }

        <FormPanel enabled={this.getCurrStep() >= 3} active={this.getCurrStep() == 3} colors={colors}>
          <table border="0" width="100%">
            <tr>
              <td width="50%" align="left" valign="middle">
                <h2><big style={{fontWeight: 'bold'}}>{this.props.noStudents ? 2 : 3}.</big> I tuoi contatti {this.state.teacher ? <small className="ml-2"><i className="fa fa-check text-done" /> sig./ra {this.state.teacher.last_name}, dati salvati</small>: ''}</h2>
              </td>
              <td width="50%" align="right" valign="middle">
                {this.state.teacherConfirmed ? <button className="btn" style={editButtonStyle} onClick={this.handleUnconfirmTeacher}><i className="fa fa-pencil" /> Modifica</button> : ''}
              </td>
            </tr>
          </table>

          {step3}
        </FormPanel>

        <FormPanel enabled={this.getCurrStep() >= 4} active={this.getCurrStep() == 4} colors={colors}>
          <h2><big style={{fontWeight: 'bold'}}>{this.props.noStudents ? 3 : 4}.</big> Conferma</h2>
          {step4}
        </FormPanel>
      </div>
    );
  }
}

export default RequestForm;