import React from 'react';

class FormPanel extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let style = {};
    const HIGHLIGHT_ACTIVE = false;

    if (!this.props.enabled) {
      style.opacity = 0.5;
    } else {
      style.opacity = 1;
    }

    if (this.props.active && HIGHLIGHT_ACTIVE) {
      style.backgroundColor = this.props.colors.lighter;
      style.borderRadius = '0.6vw';
      style.padding = '0.2vw 0.5vw';
      style.marginTop = '-0.2vw';
      style.marginLeft = '-0.5vw';
    }  

    return (
      <div className="mt-3 mb-4" style={style}>
        {this.props.children}
      </div>
    );
  }
}

export default FormPanel;