import React from 'react';
import ReactDOM from 'react-dom';

import Settings from './config.js';

import RequestForm from './components/RequestForm';

var colors = (function() {
  function toRGB(color) {
    /* Check for # infront of the value, if it's there, strip it */
    if (color.substring(0,1) == '#') {
       color = color.substring(1);
     }

    /* Grab each pair (channel) of hex values and parse them to ints using hexadecimal decoding */
    var sfdlkj = {};
    sfdlkj.r = parseInt(color.substring(0,2), 16);
    sfdlkj.g = parseInt(color.substring(2,4), 16);
    sfdlkj.b = parseInt(color.substring(4), 16);

    return 'rgb(' + [sfdlkj.r, sfdlkj.g, sfdlkj.b].join(', ') + ')';
  }

  function alterColor(rgb, type, percent) {
    if (!rgb) {
      console.error('RGB color invalid');

      return false;
    }

    rgb = rgb.replace('rgb(', '').replace(')', '').split(',');

    var red = rgb[0].replace(' ', '');
    var green =rgb[1].replace(' ', '');
    var blue = rgb[2].replace(' ', '');

    //If rgb is black set it to gray
    if (red == 0 && green == 0 && blue == 0) {
      red = 100;
      green = 100;
      blue = 100;
    }

    if (type === "darken") {
      red = parseInt(red * (100 - percent) / 100, 10);
      green = parseInt(green * (100 - percent) / 100, 10);
      blue = parseInt(blue * (100 - percent) / 100, 10);
    } else {
      red = parseInt(red * (100 + percent) / 100, 10);
      green = parseInt(green * (100 + percent) / 100, 10);
      blue = parseInt(blue * (100 + percent) / 100, 10);
    }

    rgb = 'rgb(' + red + ', ' + green + ', ' + blue + ')';

    return rgb;
  }

  var color = document.getElementById('progettiedu_kitrequest_form').getAttribute('data-color');
  if (color) {
    let primary = color;
    let darker = alterColor(toRGB(color), 'darken', 20);
    let lighter = alterColor(toRGB(color), 'darken', 20);

    var colors = {
      text: '#FFFFFF',
      primary: primary,
      darker: darker,
      lighter: lighter
    };
  }

  if (!colors) {
    var colors = {
      text: '#FFFFFF',
      primary: '#666666',
      darker: '#333333',
      lighter: '#999999'
    };
  }

  return colors;
})();

const noStudents = document.getElementById('progettiedu_kitrequest_form').getAttribute('data-no-students') == "true";
const noShipping = document.getElementById('progettiedu_kitrequest_form').getAttribute('data-no-shipping') == "true";
const downloadUrl = document.getElementById('progettiedu_kitrequest_form').getAttribute('data-download-url');
const refCode = document.getElementById('progettiedu_kitrequest_form').getAttribute('data-ref-code');

let callback = null;
if (window.progettiedu_kitrequest_form_callback !== undefined) {
  callback = window.progettiedu_kitrequest_form_callback;
}

const comp = <RequestForm colors={colors} kits="" noStudents={noStudents} noShipping={noShipping} downloadUrl={downloadUrl} refCode={refCode} onSuccess={callback} />;

ReactDOM.render(
  comp,
  document.getElementById('progettiedu_kitrequest_form')
);

if (module.hot) {
  module.hot.accept('./components/RequestForm', () => {
    const NextRequestForm = require('./components/RequestForm').default;

    ReactDOM.render(
      comp,
      document.getElementById('progettiedu_kitrequest_form')
    );
  })
}
