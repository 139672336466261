import React from 'react';

import Settings from '../config.js';

import Tooltip from './Tooltip';

class SchoolPickerLegacyForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fetching: false,

      provinces: [],
      cities: [],

      validates: false
    };

    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleChangeProvince = this.handleChangeProvince.bind(this);
    this.getCityData = this.getCityData.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    this.setState({
      fetching: true
    });

    fetch(Settings.LOCATIONS_API_URL.replace(/\/+$/, '') + '/locations?type=province&access_token=' + Settings.LOCATIONS_API_ACCESS_TOKEN)
    .then(response => {
      return response.json();
    })
    .then(data => {
      if (data.ok == false) {
        alert("Errore: " + data.error);
      }

      this.setState({
        fetching: false,
        provinces: data && data.locations ? data.locations : []
      });
    });
  }

  getCityData(id) {
    for (var i=0; i<this.state.cities.length; i++) {
      var city = this.state.cities[i];

      if (city.id == id) {
        return city;
      }
    }

    return false;
  }

  handleChangeProvince(e) {
    var provId = e.target.value;

    this.setState({
      fetching: true,

      cities: []
    });

    fetch(Settings.LOCATIONS_API_URL.replace(/\/+$/, '') + '/locations?province=' + provId + '&access_token=' + Settings.LOCATIONS_API_ACCESS_TOKEN)
    .then(response => {
      return response.json();
    })
    .then(data => {
      if (data.ok == false) {
        alert("Errore: " + data.error);
      }

      this.setState({
        fetching: false,
        cities: data && data.locations ? data.locations : []
      });
    });
  }

  handleFormChange(e) {
    var form = document.getElementById('colibri-progettiedu-kitrequests-form-legacy');
    if (!form) {
      alert('no form');
    }

    var validates = true;

    ['school_name', 'address', 'city', 'postcode', 'province', 'email', 'phone', 'manager_name'].forEach(function(field) {
      if (!form[field].value) {
        validates = false;
      }
    });

    this.setState({
      validates: validates
    });
  }

  handleSubmit() {
    var form = document.getElementById('colibri-progettiedu-kitrequests-form-legacy');
    if (!form) {
      alert('no form');
    }

    var locationId = form['city'].value;
    var cityName = '';
    var provinceName = '';
    var regionName = '';

    this.state.cities.forEach(function(city) {
      if (city.id == locationId) {
        cityName = city.name,
        provinceName = city.province && city.province.iso_code ? city.province.iso_code : '',
        regionName = city.region ? city.region.name : ''
      }
    });

    var data = {
      name: form['school_name'].value,

      address: form['address'].value,
      postcode: form['postcode'].value,
      location_id: form['city'].value,
      city_name: cityName,
      province_iso_code: provinceName,
      region_name: regionName,

      email: form['email'].value,
      phone: form['phone'].value,
      manager_name: form['manager_name'].value,
    };

    this.props.onSubmit(data);
  }

  render() {
    var provinceOptions = this.state.provinces.map(function(loc) {
      return <option value={loc.id}>{loc.name}</option>
    });
    var cityOptions = this.state.cities.map(function(loc) {
      return <option value={loc.id}>{loc.name}</option>
    });

    return (
      <div>
        <form id="colibri-progettiedu-kitrequests-form-legacy" className="form" onChange={this.handleFormChange}>
          <div className="form-group">
            <div className="row">
              <div className="col-12">
                <label for="school_name">Nome scuola</label>
                <input className="form-control" type="text" name="school_name" placeholder="" />
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-12">
                <label for="address">Indirizzo</label>
                <textarea className="form-control" name="address"></textarea>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-sm-12 col-md-4">
                <label for="province">Provincia</label>
                <select className="form-control" name="province" onChange={this.handleChangeProvince}>
                  <option value="">- sel -</option>
                  {provinceOptions}
                </select>
              </div>
              <div className="col-sm-12 col-md-4">
                <label for="city">Citt&agrave;</label>
                <select className={'form-control' + (this.state.cities.length == 0 ? ' disabled' : '')} disabled={(this.state.cities.length == 0 ? ' disabled' : '')} name="city">
                  <option value="">{(this.state.cities.length == 0 ? '← seleziona provincia' : '- sel -')}</option>
                  {cityOptions}
                </select>
              </div>
              <div className="col-sm-12 col-md-4">
                <label for="postcode">CAP</label>
                <input className="form-control" type="text" name="postcode" placeholder="" />
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-sm-12 col-md-4">
                <label for="email">Email</label>
                <input className="form-control" type="email" name="email" />
              </div>
              <div className="col-sm-12 col-md-4">
                <label for="phone">Telefono</label>
                <input className="form-control" type="text" name="phone" />
              </div>
              <div className="col-sm-12 col-md-4">
                <label for="manager_name">Dirigente scolastico</label>
                <input className="form-control" type="text" name="manager_name" placeholder="" />
              </div>
            </div>
          </div>

        </form>

        <div className="mt-3">
          <a href="javascript:void(0);" className={'btn btn-light mr-1' + (this.state.validates == false ? ' disabled' : '')} onClick={this.handleSubmit}><i className="fa fa-save" /> Conferma</a> {this.state.validates ? '' : <small><i className="fa fa-exclamation-triangle" /> compila tutti i campi</small>}

          <Tooltip colors={this.props.colors}>
            <h3>Scrivi i dati della scuola per la quale stai richiedendo il kit.</h3>
            <p>Compila tutti i campi e clicca sul tasto "Conferma".</p>
          </Tooltip>
        </div>
      </div>
    );
  }
}

export default SchoolPickerLegacyForm
