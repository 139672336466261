import React from 'react';

import Tooltip from './Tooltip';

import Settings from '../config.js';

import Page from './lib/Page.js';

console.dir(Page);

class SchoolPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fetching: 0, // int-based to create a fetching queue
      controller: null,

      searchTerm: '',
      results: []
    };

    this.handleSearchTerm = this.handleSearchTerm.bind(this);
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
    this.handleResultSelect = this.handleResultSelect.bind(this);
    this.handleExit = this.handleExit.bind(this);
  }

  handleSearchTerm = (e) => {
    window.clearTimeout(window.__Colibri_Libri_KitRequestForm_SchoolPicker_timeout);

    const searchTerm = e.target.value;

    this.setState({
      searchTerm
    });

    window.__Colibri_Libri_KitRequestForm_SchoolPicker_timeout = window.setTimeout(this.handleSearchSubmit, 500);
  }

  handleSearchSubmit = (e) => {
    if (e) e.preventDefault();

    // abort previous call
    if (this.state.controller) {
      this.state.controller.abort();
    }

    this.setState({
      results: []
    });

    if (this.state.searchTerm == '') {
      return;
    }

    this.setState({
      fetching: this.state.fetching + 1
    });

    const endpoint = Settings.SCHOOLS_API_ENDPOINT + '?q=' + this.state.searchTerm + '&access_token=' + Settings.SCHOOLS_API_ACCESS_TOKEN;
    const controller = Page.prototype.fetch('GET', endpoint, {}, {}, (result, error) => {
      this.setState({
        fetching: this.state.fetching - 1
      });

      if (error) {
        console.error(error);
      } else {
        this.setState({
          results: result.data && result.data.schools ? result.data.schools : []
        });
      }
    });
    this.setState({
      controller
    });

    return false;
  }

  handleResultSelect = (result) => {
    // this.setState({
    // fetching: this.state.fetching - 1
    //   searchTerm: '',
    //   results: []
    // });

    this.props.onSelect(result);
  }

  handleExit() {
    this.props.onExit();
  }

  render() {
    let results = {
      'best': [],
      'best_html': [],
      'others': [],
      'others_html': []
    };

    const MAX_ITEMS = 10;

    if (this.state.results.length) {
      let numPrinted = 0;
      this.state.results.forEach((school) => {
        if (numPrinted <= MAX_ITEMS) {
          results['best'].push(school);
        } else {
          results['others'].push(school);
        }

        numPrinted++;
      });

      // sort "other" alphabetically
      if (results['others'].length) {
        results['others'] = results['others'].sort(function(a, b) {
          var textA = a.name.replace('"', '').toUpperCase();
          var textB = b.name.replace('"', '').toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
      }

      ['best', 'others'].forEach((type) => {
        let key = type + '_html';

        results[key] = results[type].length == 0 ? [] : results[type].map((school) => {
          const name = school.name;
          const address = school.address + ' ' + school.postcode + ' ' + school.city_name + ' (' + school.province_iso_code + ')';
          const percentageScore = Number(school._score / (results['best'][0]['_score'] / 100)).toFixed(0);

          return (
            <table border="0" width="100%" className="mb-2" style={{borderBottom: '1px solid #ccc'}}>
              <tbody>
                <tr>
                  <td>
                    <h3 style={{margin: 0}}>
                    {name}
                    {
                      school.parent_school ? (
                        <span className="ml-2" style={{fontSize: '60%'}}><small>parte di:</small> {school.parent_school.name}</span>
                      ) : ''
                    }
                    </h3>
                    <p style={{marginBottom: 0}}>
                      {school.address ? school.address : '-'}<br />
                      {school.city_name + ' ' + (school.city_name != school.province_iso_code ? '(' + school.province_iso_code + ')' : '')}
                    </p>
                  </td>
                  <td>

                  </td>
                  <td width="25">
                    <button className="btn btn-default" onClick={(e) => {this.handleResultSelect(school)}}>Seleziona</button>
                  </td>
                </tr>
              </tbody>
            </table>
          );
        });
      });
    }

    var status;
    if (this.state.fetching > 0) {
      status = <p className="mt-0 mb-0"><big><i className="fa fa-spin fa-circle-notch mr-1"></i>{this.state.results.length ? "attendere..." : "ricerca in corso..."}</big></p>;
    } else {
      if (!this.state.results.length) {
        status = (
          <div>
            <h3 className="mt-0 mb-1">Scrivi il nome della tua scuola</h3>
            <p className="mt-2 mb-0">
            {this.state.searchTerm && !this.state.results.length ? <span>Non trovi la tua scuola? <a style={{color: this.props.colors.text, textDecoration: 'underline'}} href="javascript:void(0);" onClick={this.handleExit}>inserisci manualmente</a></span> : <span>Ad esempio: <strong>Liceo Statale Machiavelli, Firenze</strong></span>}
            </p>
          </div>
        );
      } else {
        status = (
          <div>
            <table width="100%">
              <tbody>
                <tr>
                <td width="50%" align="left" valign="middle">
                  <h3 className="mb-0">Seleziona la tua scuola dall'elenco</h3>
                </td>
                <td width="50%" align="right" valign="middle">

                </td>
                </tr>
              </tbody>
            </table>
            <p className="mt-2 mb-0">Se non &egrave; presente, cerca usando altri termini oppure <a style={{color: this.props.colors.text, textDecoration: 'underline'}} href="javascript:void(0);" onClick={this.handleExit}>inserisci manualmente</a>.</p>
          </div>
        );
      }
    }

    return (
      <div>
        <table border="0" width="100%">
          <tbody>
            <tr>
              <td>
                <input
                  type="search"
                  value={this.state.searchTerm}
                  placeholder={this.props.placeholder}

                  onChange={this.handleSearchTerm}

                  className="form-control"
                />
              </td>
              <td></td>
              <td>
                <button
                  className="btn btn-default ml-2"
                  onClick={this.handleSearchSubmit}
                  disabled={this.state.fetching > 0 || !this.state.searchTerm ? "disabled" : ""}
                >
                  {this.props.button ? this.props.button : "Cerca"}
                </button>
              </td>
            </tr>
          </tbody>
        </table>

        <div className="mt-3">
          <Tooltip colors={this.props.colors}>
            <p>{status}</p>
            {
              results && results['best'].length ? (
                <div>
                  <div className="alert alert-light">{results['best_html']}</div>

                  <h5>Altri risultati <small>(in ordine alfabetico)</small></h5>
                  <div className="alert alert-light">{results['others_html']}</div>
                </div>
              ) : ""
             }
          </Tooltip>
        </div>
      </div>
    );
  }
}

export default SchoolPicker
