import React from 'react';

class Tooltip extends React.Component {
  constructor(props) {
    super(props);

    this.defaultColor = '#158CBA';
  }

  render() {
    var tooltipStyle = {
      position: 'relative', 
      zIndex: 99999999, 
      
      width: '100%', 
      marginTop: '20px',

      color: this.props.colors.text,
      backgroundColor: this.props.colors.primary,      
      borderColor: this.props.colors.darker
    };

    var stemStyle = {
      position: 'relative', 
      top: '-12px', 
      margin: 0, 
      marginBottom: '-12px', 
      marginLeft: '20px',
      
      textAlign: 'left',
      borderColor: this.props.colors.darker + ' !important'
    };

    return (
      <div className="alert px-3 py-0" style={tooltipStyle}>
        {this.props.badge ? <span className="badge badge-light" style={{position: 'absolute', top: '10px', right: '10px'}}>{this.props.badge}</span> : ''}

        <p style={stemStyle}>
          <i className="fa fa-triangle" style={{
            fontSize: '16px', 
            color: this.props.colors.primary,
            textShadow: '0 -4px 0px ' + this.props.colors.darker,
          }} />
        </p>
        
        {this.props.children}
      </div>
    );
  }
}

export default Tooltip;