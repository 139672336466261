import React, { Component, Fragment } from 'react';

class XHR {
  constructor() {
    this.fetching = [];

    this.track = this.track.bind(this);
    this.fetch = this.fetch.bind(this);
    this.abortAll = this.abortAll.bind(this);
  }

  // keep track of resources fetched
  // (used mainly to abort calls being fetched)
  track(method, endpoint, controller) {
    this.fetching.push({
      controller,
      method,
      endpoint
    });
  }

  fetch(method, endpoint, payload, options, callback) {
    function serialize(obj) {
      var str = [];
      for (var p in obj) {
        if (obj.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
      }

      return str.join("&");
    }

    // normalize method
    method = String(method).toUpperCase();

    // payload should be an object, convert to JSON if necessary
    payload = typeof payload == 'string' ? payload : JSON.stringify(payload);

    // handle headers
    let defaultHeaders = {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      'Authorization': ''
    }
    let headers = options && options.headers ? Object.assign(defaultHeaders, options.headers) : defaultHeaders;

    // do actual call
    const controller = new window.AbortController();
    const signal = controller.signal;

    this.track(method, endpoint, controller);

    switch (method) {
      case 'GET':
        endpoint += (endpoint.indexOf('?') == -1 ? '?' : '&') + serialize(payload);
        fetch(endpoint, {
          method: 'GET',
          mode: 'cors',
          cache: "no-cache",
          headers,
          body: null,
          signal
        })
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          callback(result, null);
        })
        .catch((error) => {
          callback(null, error);
        })
        break;
      case 'POST':
      case 'PUT':
      case 'DELETE':
       fetch(endpoint, {
          method,
          mode: 'cors',
          cache: "no-cache",
          headers,
          body: payload,
          signal
        })
        .then(result => callback(result, null))
        .catch(error => callback(null, error))
        break;
      default:
        console.error('API method ' + method + ' not supported. Sorry');

        return false;
    }

    return controller;
  }

  abortAll() {
    this.fetching.forEach((resource) => {
      resource.controller.abort();
    });
  }
}

class Page extends Component {
  constructor(props) {
    super(props);

    this.__XHR = new XHR();

    this.fetch = this.fetch.bind(this);
  }

  fetch(method, endpoint, payload, options, callback) {
    return (new XHR()).fetch(method, endpoint, payload, options, callback);
  }
}

export default Page;