import React from 'react';

import Settings from '../config.js';

import Tooltip from './Tooltip';

class StudentPicker extends React.Component {
  constructor(props) {
    super(props);

    this.MAX_NUM_STUDENTS = this.props.maxStudents || Infinity;

    this.state = {
      submittable: false,

      formEnabled: this.props.editable && this.props.schoolclasses.length == 0,
      numStudentsError: false,
      uniqueSchoolclassesError: false,

      schoolclasses: [],

      schoolclassesGroups: [],
      schoolclassesTypes: []
    };

    this.prettySchoolclassType = this.prettySchoolclassType.bind(this);
    this.handleEnableForm = this.handleEnableForm.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleSchoolclassAdd = this.handleSchoolclassAdd.bind(this);
    this.handleSchoolclassRemove = this.handleSchoolclassRemove.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentWillMount() {
    var endpoint = Settings.API_V3_URL.replace(/\/+$/, '') + '/schoolclasses/types';
    fetch(endpoint)
      .then(response => {
        return response.json();
      })
      .then(response => {
        if (response.ok == false) {
          alert("Errore: " + response.error);
        }

        this.setState({
          fetching: false,
          schoolclassesTypes: response.data && response.data.types ? response.data.types : [],
          schoolclassesGroups: response.data && response.data.groups ? response.data.groups : []
        });
      });
  }

  prettySchoolclassType(type) {
    if (!this.state.schoolclassesTypes || !this.state.schoolclassesTypes.length) {
      return '';
    }

    let name = '';
    this.state.schoolclassesTypes.forEach((schoolclass, i) => {
      if (schoolclass.id == type) {
        name = schoolclass.name;

        return;
      }
    });

    return name;
  }

  handleFormChange = (e) => {
    const form = e.currentTarget;

    let ok = true;
    let uniqueSchoolclassesError = false;

    // if students are too many, show error message (form will be set to MAX_NUM_STUDENTS automatically)
    let numStudents = form.num_students.value;
    if (numStudents > this.MAX_NUM_STUDENTS) {
      numStudents = this.MAX_NUM_STUDENTS;

      form.num_students.value = this.MAX_NUM_STUDENTS;

      // ...then,
      this.setState({
        numStudentsError: true
      }, () => {
        window.setTimeout(() => {
          this.setState({
            numStudentsError: false
          });
        }, 1000);
      });
    }

    // make sure we have all data
    if (!form.schoolyear.value || !form.class_type.value || !form.class_sec.value || form.num_students.value <= 0 || numStudents >= this.MAX_NUM_STUDENTS) {
      ok = false;
    }

    // make sure schoolclasses are unique
    this.props.schoolclasses.forEach((schoolclass) => {
      if (schoolclass.schoolyear == form.schoolyear.value && schoolclass.type == form.class_type.value && schoolclass.sec == form.class_sec.value) {
        ok = false;
        uniqueSchoolclassesError = true;
      }
    });

    this.setState({
      submittable: ok,

      uniqueSchoolclassesError: uniqueSchoolclassesError
    });
  }

  handleEnableForm() {
    this.setState({
      formEnabled: true
    });
  }

  handleSchoolclassAdd(e) {
    e.preventDefault();

    var form = e.currentTarget;

    var schoolclass = {
      schoolyear: form.schoolyear.value,
      type: form.class_type.value,
      sec: form.class_sec.value,
      students: form.num_students.value
    };

    this.props.onSchoolclassAdd(schoolclass);

    form.querySelectorAll('select, input').forEach(function(elem) {
      elem.value = '';
    });
    form.querySelectorAll('select[name="schoolyear"]').forEach(function(elem) {
      elem.value = (new Date()).getFullYear();
    });

    this.setState({
      submittable: false,

      formEnabled: false
    });

    return false;
  }

  handleSchoolclassRemove(schoolclass) {
    this.props.onSchoolclassRemove(schoolclass);

    let submittable = this.props.schoolclasses.length - 1;

    this.setState({
      submittable: submittable,

      formEnabled: !submittable
    });
  }

  handleSave() {
    this.props.onSave();
  }

  render() {
    var currYear = (new Date).getFullYear();
    var schoolclasses = [];

    // handle chosen classes
    for (var i=0; i<this.props.schoolclasses.length; i++) {
      let schoolclass = this.props.schoolclasses[i];
      schoolclasses.push(
        <div className="alert alert-light">
          <table border="0" width="100%">
            <tbody>
              <tr>
                <td width="70%" align="left" valign="middle">
                  <h3>
                    {this.prettySchoolclassType(schoolclass.type)} sez. {schoolclass.sec}
                  </h3>
                  <p className="mb-0" style={{whiteSpace: 'nowrap'}}>{schoolclass.students}</p>
                </td>
                <td width="30%" align="right" valign="middle">
                  {this.props.editable ? <a className="align-middle" href="javascript:void(0);" onClick={function() {this.handleSchoolclassRemove(schoolclass)}.bind(this)}><i className="fa fa-times" /> rimuovi</a> : ''}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }

    if (this.props.editable) {
      let schoolclassesOptions = [];
      if (this.state.schoolclassesGroups &&  this.state.schoolclassesGroups.length) {
        this.state.schoolclassesGroups.forEach((group, i) => {
          if (i > 0) {
            schoolclassesOptions.push(<option value="">--------</option>);
          }

          group.schoolclasses.map((schoolclass) => {
            schoolclassesOptions.push(<option value={schoolclass.id}>{schoolclass.name}</option>);
          });
        })
      }

      var form = (
        <form className="form"  onChange={this.handleFormChange} onSubmit={this.handleSchoolclassAdd}>
          <input type="hidden" name="schoolyear" value={currYear} />
          <div className="row mt-2 mb-2">
            <div className="col-5">
              <label for="class_type">
                Classe
                {
                  this.state.uniqueSchoolclassesError && <small className="text-danger"> — ATTENZIONE: hai già aggiunto questa classe</small>
                }
              </label>
              <select name="class_type" className="form-control">
                <option value="" selected disabled>- sel -</option>
                {schoolclassesOptions}
              </select>
            </div>
            <div className="col-2">
              <label for="class_type">Sez.</label>
              <select name="class_sec" className="form-control">
                <option value="" selected disabled>- sel -</option>
                <option value="U">Unica</option>
                <option value="">---</option>
                <option value="A">A</option>
                <option value="B">B</option>
                <option value="C">C</option>
                <option value="D">D</option>
                <option value="E">E</option>
                <option value="F">F</option>
                <option value="G">G</option>
                <option value="H">H</option>
                <option value="I">I</option>
                <option value="L">L</option>
                <option>M</option>
                <option>N</option>
                <option>O</option>
                <option>P</option>
                <option>Q</option>
                <option>R</option>
                <option>S</option>
                <option>T</option>
                <option>U</option>
                <option>V</option>
                <option>Z</option>
              </select>
            </div>
            <div className="col-3">
              <label>
                Alunni
              </label>
              <div className="row">
                <div className="col-12 px-0" style={{whiteSpace: 'nowrap'}}>
                  <input type="number" name="num_students" className="form-control" />
                </div>
              </div>
            </div>
            <div className="col-2 text-center">
              <label>&nbsp;</label><br />
              <button type="submit" className="btn btn-default" disabled={!this.state.submittable ? 'disabled' : ''}><i className="fa fa-plus" /> aggiungi</button>
            </div>
          </div>
        </form>
      );

      return (
        <div>
          {schoolclasses}

          {this.state.formEnabled ? form : ''}

          {/* <p><a href="javascript:void(0);" className="btn btn-sm btn-secondary mr-1"><i className="fa fa-check" /> conferma</a> oppure <a href="javascript:void(0);">aggiungi un'altra classe</a>.</p>
          {this.props.schoolclasses.length >= 0 ? <p><a href="javascript:void(0);"><i className="fa fa-plus" /> aggiungi un'altra classe</a></p> : ''} */}

          <div className="mt-4">
            <Tooltip colors={this.props.colors}>
              {
                this.state.formEnabled ? (
                  <div>
                    <h3>Seleziona la classe per cui richiedi il kit didattico.</h3>
                    <p>Compila tutti i campi e clicca su "aggiungi"</p>
                  </div>
                ) : (
                  <div>
                    <h3>Vuoi aggiungere un'altra classe?</h3>
                    <p className="mt-2">
                      <a href="javascript:void(0);" className={'btn btn-light mr-1' + (schoolclasses.length == 0 ? ' disabled' : '')} onClick={this.handleEnableForm}><i className="fa fa-plus" /> aggiungi</a>
                      <span className="ml-1 mr-1">&nbsp;oppure&nbsp;</span>
                      <a href="javascript:void(0);" className={'btn btn-light mr-1' + (schoolclasses.length == 0 ? ' disabled' : '')} onClick={this.handleSave}><i className="fa fa-save" /> finito</a>
                    </p>
                  </div>
                )
              }
            </Tooltip>

            {/* <p>Oppure,  l'aggiunta di {this.props.schoolclasses.length} classe/i.</p> */}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          {schoolclasses}
        </div>
      );
    }
  }
}

export default StudentPicker;