import React from 'react';

import Tooltip from './Tooltip';

class TeacherInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submittable: false,

      formData: {}
    };

    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    if (this.props.teacher) {
      // prefill
      var submittable = true;
      ['first_name', 'last_name', 'email', 'phone', 'subject'].forEach(function(field) {
          if (this.props.teacher[field] == false) {
            submittable = false;
          }
      }.bind(this));
      
      this.setState(function(prevState) {
        prevState.formData = this.props.teacher;
        prevState.submittable = submittable;
  
        return prevState;
      });
    }
  }

  handleFormChange(e) {
    var form = e.currentTarget;
    var elements = form.elements;

    var submittable = true;

    for (var i = 0 ; i < elements.length ; i++) {
      var item = elements.item(i);

      if (!item.name) {
        continue;
      }

      var formData = this.state.formData;
      formData[item.name] = item.value;
      this.setState({
        formData: formData
      });
      
      if (item.value == false) {
        submittable = false;
      }
    }

    this.setState({
      submittable: submittable
    });
  }  

  handleSave(e) {
    e.preventDefault();

    var elements = e.target.elements;
    var data ={};
    for (var i = 0 ; i < elements.length ; i++) {
      var item = elements.item(i);

      if (!item.name) {
        continue;
      }
      
      if (!item.value) {
        return false;
      }

      data[item.name] = item.value;
    }

    this.props.onSave(data);

    return false;
  }

  render() {
    if (this.props.editable) {
      var requiredFieldsAll = [
        {
          name: 'first_name', 
          label: 'nome'
        },
        {
          name: 'last_name', 
          label: 'cognome'
        },
        {
          name: 'subject', 
          label: 'disciplina di insegnamento'
        }, 
        {
          name: 'email', 
          label: 'email'
        },
        {
          name: 'phone',
          label: 'telefono'
        }
      ];
      var requiredFieldsMissing = [];
      var requiredFieldsMessage = '';
      var that = this;

      // console.dir(that.state.formData);

      requiredFieldsAll.forEach(function(field) {
        if (that.state.formData[field.name] == undefined || that.state.formData[field.name] == false) {
          requiredFieldsMissing.push(field.label);
        }
      });
      requiredFieldsMessage = <small className="text-danger"><i className="fa fa-exclamation-triangle" /> Inserisci {requiredFieldsMissing.join(', ')}</small>;

      return (
        <div>
          <form id="progettiedu_requestkit_teacher_form" className="my-2" onChange={this.handleFormChange} onSubmit={this.handleSave}>
            <div className="row mb-3">
              <div className="col-6">
                <label>Disciplina di insegnamento</label>
                <select name="subject" className="form-control" defaultValue={this.props.teacher ? this.props.teacher.subject : ''}>
                  <option value="" selected="selected" data-i="0">- sel -</option>
                  <option value="Arte ed immagine">Arte ed immagine</option>
                  <option value="Educazione fisica">Educazione fisica</option>
                  <option value="Geografia">Geografia</option>
                  <option value="Inglese">Inglese</option>
                  <option value="Italiano">Italiano</option>
                  <option value="Matematica">Matematica</option>
                  <option value="Musica">Musica</option>
                  <option value="Religione">Religione</option>
                  <option value="Scienze">Scienze</option>
                  <option value="Storia">Storia</option>
                  <option value="Tecnologia ed informatica">Tecnologia ed informatica</option>
                  <option value="">---</option>
                  <option value="Altro">Altro</option>
                </select>
              </div>
            </div>                                      
            <div className="row mb-2">
              <div className="col-6">
                <label for="first_name">Nome</label>
                <input type="text" name="first_name" defaultValue={this.props.teacher ? this.props.teacher.first_name : ''} className="form-control" />
              </div>
              <div className="col-6">
                <label for="last_name">Cognome</label>
                <input type="text" name="last_name" defaultValue={this.props.teacher ? this.props.teacher.last_name : ''} className="form-control" />
              </div>            
            </div>
            <div className="row mb-2">
              <div className="col-6">
                <label>Email</label>
                <input type="email" name="email" defaultValue={this.props.teacher ? this.props.teacher.email : ''} className="form-control" />
              </div>          
              <div className="col-6">
                <label for="full_name">Telefono</label>
                <input type="text" name="phone" defaultValue={this.props.teacher ? this.props.teacher.phone : ''} className="form-control" />
              </div>
            </div>          

            <p className="mt-3">
              <button type="submit" className={'btn mr-1' + (this.state.submittable == false ? ' disabled' : '')}><i className="fa fa-save" /> Conferma</button> {this.state.submittable == false ? requiredFieldsMessage : ''}
            </p>

            <Tooltip colors={this.props.colors}>
              <h3>Inserisci i tuoi dati.</h3>
              <p>Compila tutti i campi, e clicca su "conferma" quando hai finito.</p>
            </Tooltip>
          </form>
        </div>
      );
    } else {
      var teacher = this.props.teacher;
      return (
        <div className="alert alert-light">
          <img style={{float: 'left', marginRight: '0.7vw', width: '8vw', maxWidth: '70px'}} src="/silueta.jpg" />

          <h1 className="mb-0">{teacher.first_name} {teacher.last_name}</h1>
          <h3><i className="fa fa-envelope" /> {teacher.email} <i className="fa fa-phone" /> {teacher.phone}</h3>
        </div>
      );
    }
  }
}

export default TeacherInput;